import { Component, Prop, Vue } from 'vue-property-decorator'

import links from '@/services/links';

@Component({

})
export default class PublicNavbar extends Vue {

  links = links

}
